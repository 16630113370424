@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.speechRecorder {
  transition: background 0.4s ease-in-out, color 0.4s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin: 0 1rem;
  padding: 0;
  background: #65ab00;
  border-radius: 50%;
  border: none;
  outline: none;
  color: #ffffff60;
  font-size: 4rem;
  cursor: pointer;
}

.speechRecorder::before {
  animation: spin 4s linear infinite;
  transition: opacity 0.4s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4.55rem;
  height: 4.55rem;
  margin: -0.05rem;
  padding: -0.4rem;
  background: transparent;
  opacity: 0;
  border-radius: 50%;
  border: 0.35rem solid white;
  border-top-color: #ed6a5f;
}

.speechRecorder.active {
  background: #ed6a5f;
  color: white;
}

.speechRecorder.disabled {
  background: lightgray;
  color: white;
}

.speechRecorder.active::before {
  opacity: 1;
}

.button--disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: "no-drop";
}
