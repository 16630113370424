.pulse__icon__container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.pulse__icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(255, 57, 43, 0.65);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 57, 43, 0.65);
  }
  70% {
    -webkit-box-shadow: 0 0 0 1rem rgba(255, 57, 43, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 57, 43, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 57, 43, 0.65);
    box-shadow: 0 0 0 0 rgba(255, 57, 43, 0.65);
  }
  70% {
    -moz-box-shadow: 0 0 0 1rem rgba(255, 57, 43, 0);
    box-shadow: 0 0 0 1rem rgba(255, 57, 43, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 57, 43, 0);
    box-shadow: 0 0 0 0 rgba(255, 57, 43, 0);
  }
}
